import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
// import { Roadmap } from './pages/Roadmap';
import { Bulletin } from './pages/Bulletin';
import { BulletinList } from './pages/BulletinList';
import { Status } from './pages/Status';
import { KnownIssuesList } from './pages/KnownIssuesList';
import { NotFound } from './pages/404';

import './boostrap.scss';
import './style.scss'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (<div>
        <Layout>
          <Switch>
            <Route exact path='/' component={Home} />
            {/* <Route path='/roadmap' component={Roadmap} /> */}
            <Route path='/bulletin/:id' component={Bulletin} />
            <Route path='/bulletins' component={BulletinList} />
            <Route path='/status' component={Status} />
            <Route path='/knownissues' component={KnownIssuesList} />
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </div>
    );
  }
}
