import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, Container, Jumbotron, PaginationLink } from 'reactstrap';
import moment from 'moment';
import './styles/Bulletin.scss';

export class Bulletin extends Component {
  static displayName = Bulletin.name;

  constructor(props) {
    super(props);
    this.state = {
      contentId: this.props.match.params.id,
      bulletinContent: null
    };

    window.document.title = "Bulletin | IT Trust Center";
  }
  
  componentDidMount() {
    this.getBulletinContent(this.state.contentId);
  }

  async getBulletinContent() {
    this.setState({loading: true});

    const response = await fetch('api/bulletin/' + this.state.contentId);
    const data = await response.json();

    this.setState({ bulletinContent: data, loading: false });
  }

  render() {
    if (!this.state.bulletinContent) {
      return <Container class="mt-3"><p>Loading...</p></Container>;
    }

    window.document.title = this.state.bulletinContent.title + " | IT Trust Center";

    return (
      <div className="bulletin">
        <Jumbotron fluid>
          <Container>
            <div className="h6"><Link to="/bulletins">IT Bulletin</Link></div>
            <h1 className="display-6">{this.state.bulletinContent.title}</h1>
            <hr className="my-2" />
            <p className="date created"><em>Updated: {moment(this.state.bulletinContent.updated).format("MMM D, YYYY h:MM A")}</em></p>
          </Container>
        </Jumbotron>
        <Container>
          <Card className="bulletin-view">
            <CardBody>
              <Row>
                <Col md="12">
                  <div className="content" dangerouslySetInnerHTML={{ __html: this.state.bulletinContent.body }}></div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }

}
