import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Jumbotron, Container, Row, Col, Card, CardImg, CardBody } from 'reactstrap';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    window.document.title = "Tuff Shed | IT Trust Center";

    return (
      <div>        
        <Jumbotron fluid className="home-jumbotron">
          <Container>
            <h1 className="display-5"><strong>Partnerships</strong> are built on <strong>trust</strong></h1>
            <hr className="my-2" />
            <p className="lead">The IT Trust Center is a dedicated location for learning how IT is partnering with you to provide the best services possible. You deserve transparency into system performance, incidents, and what IT is doing for you.</p>
          </Container>
        </Jumbotron>        
        <Container>
          <Row>          
            <Col md="3" className="mb-3">
              <Link to="/bulletins" className="home-card-link">
                <Card>
                  <div className="home-card-image">
                    <CardImg top width="100%" src="/bulletin-icon.png" alt="Bulletin icon" />
                  </div>
                  <CardBody>
                    <h3>Bulletins</h3>
                    <span>View important announcements, learn what's new, and see what we've been up to!</span>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col md="3" className="mb-3">
              <a href="https://status.tuffshed.com" className="home-card-link">
                <Card>
                  <div className="home-card-image">
                    <CardImg top width="100%" src="/status-icon.png" alt="Roadmap icon" />
                  </div>
                  <CardBody>
                    <h3>Services Status</h3>
                    <span>Information on current and past service availability, performance, and incidents</span>
                  </CardBody>
                </Card>
              </a>
            </Col>
            <Col md="3" className="mb-3">
              <Link to="/knownissues" className="home-card-link">
                <Card>
                  <div className="home-card-image">
                    <CardImg top width="100%" src="/known-issues-icon.png" alt="Known issues icon" />
                  </div>
                  <CardBody>
                    <h3>Known Issues</h3>
                    <span>Information about current known issues with our systems and available workarounds</span>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col md="3" className="mb-3">
              <a href="https://itsupport.tuffshed.com/" className="home-card-link">
                <Card>
                  <div className="home-card-image">
                    <CardImg top width="100%" src="/support-icon.png" alt="Roadmap icon" />
                  </div>
                  <CardBody>
                    <h3>Support Portal</h3>
                    <span>Visit the Support Portal to reach out for help or view our knowledge base</span>
                  </CardBody>
                </Card>
              </a>
            </Col>
            {/* <Col md="4" className="mb-3">
              <Link to="/roadmap" className="home-card-link">
                <Card>
                  <div className="home-card-image">
                    <CardImg top width="100%" src="/roadmap-icon.png" alt="Roadmap icon" />
                  </div>
                  <CardBody>
                    <h3>Roadmap</h3>
                    <span>Learn about what's in the works and any is coming to you soon</span>
                  </CardBody>
                </Card>
              </Link>
            </Col> */}
          </Row>
        </Container>
      </div>
    );
  }
}
