import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Jumbotron, Container, Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import moment from 'moment';
import './styles/KnownIssues.scss';

export class KnownIssuesList extends Component {
  static displayName = KnownIssuesList.name;

  constructor(props) {
    super(props);
    this.state = {
      knownIssues: null
    };

    window.document.title = "Known Issues | IT Trust Center";
  }
  
  componentDidMount() {
    this.getKnownIssues();
  }

  async getKnownIssues() {
    this.setState({loading: true});

    const response = await fetch('api/knownissues/list');
    const data = await response.json();

    this.setState({ knownIssues: data, loading: false });
  }

  renderKnownIssue(issue) {
    return(
      <Card className="knownissue-card" key={issue.key}>
        <CardBody>
          <h2 id={issue.key}><a href={'/knownissues#' + issue.key}>{issue.summary}</a></h2>
          <div className="info-text"><strong>Issue Key</strong>: {issue.key} | <strong>Created</strong>: {moment(issue.created).format("MMM D, YYYY h:MM A")} | <strong>Last Updated</strong>: {moment(issue.lastModified).format("MMM D, YYYY h:MM A")}</div>

          <div className="affects-wrapper">
            <h6><strong>Affects</strong>:</h6>
            <div className="affects" dangerouslySetInnerHTML={{__html: issue.affects}}></div>
          </div>
          <div className="description">
            <h6><strong>Description</strong>:</h6>
            <div className="affects" dangerouslySetInnerHTML={{__html: issue.description}}></div>
          </div>
          <div className="workaround">
            <h6><strong>Workaround</strong>:</h6>
            <div className="affects" dangerouslySetInnerHTML={{__html: issue.workaround}}></div>
          </div>
        </CardBody>
      </Card>
    );
  }
  render() {
    let innerContent = <p>Loading known issues...</p>;

    if (this.state.knownIssues && this.state.knownIssues) {

      if (this.state.knownIssues.length < 1) {
        innerContent = <div>No known issues data, or error occured.</div>
      }

      innerContent = 
          this.state.knownIssues.map((b) => 
            this.renderKnownIssue(b)
          );
    }    

    return (
      <div className="knownissues">
        <Jumbotron fluid>
          <Container>
            <h1 className="display-5">Known Issues</h1>
            <hr className="my-2" />
            <p className="lead">Information about current known issues with our systems and available workarounds. Use this list to see if an issue affecting you is already known by the support team. If contacting support about one of these known issues, please reference the <em>Issue Key</em>.</p>
          </Container>
        </Jumbotron>
        <Container>
          <Row>
            <Col md="12">
              {innerContent}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

}
