import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Row, Col, Card, CardImg, CardBody } from 'reactstrap';

export class NotFound extends Component {
  static displayName = NotFound.name;

  render() {
    window.document.title = "Not Found | IT Trust Center";

    return (
      <div>
        <Container className="mt-3">
          <Row>
            <Col>
            <h1>Ruh Roh!</h1>
            <p>It's not you... I promise... The dog ate this page...</p>
            <p><Link to="/">Take me home...</Link> To the place... Before something went wrong... Tuff Shedirginia...</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
