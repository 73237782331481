import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Jumbotron, Container, Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import moment from 'moment';
import './styles/Bulletin.scss';

export class BulletinList extends Component {
  static displayName = BulletinList.name;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      totalPages: 1,
      bulletinList: null
    };

    window.document.title = "Bulletins | IT Trust Center";

    this.paginationChangePage = this.paginationChangePage.bind(this);
    this.paginationChangePageRelative = this.paginationChangePageRelative.bind(this);
  }
  
  componentDidMount() {
    this.getBulletinList(this.state.page);
  }

  async getBulletinList() {
    this.setState({loading: true});

    const pagination = "start=" + ((this.state.page - 1) * this.state.pageSize) + "&limit=" + this.state.pageSize;
    const response = await fetch('api/bulletin/list?' + pagination);
    const data = await response.json();

    this.setState({ bulletinList: data, loading: false, totalPages: Math.ceil(data.totalCount / this.state.pageSize)});
  }

  paginationChangePage(page) {
    if (page > this.state.totalPages) return;

    this.setState({ page: page }, () => this.getBulletinList());    
  }

  paginationChangePageRelative(pageDelta) {
    var newPage = this.state.page + pageDelta;
    return this.paginationChangePage(newPage);
  }

  renderBulletinCard(content) {
    return(
      <Card className="bulletin-card" key={content.id}>
        <CardBody>
          <Link to={"/bulletin/" + content.id}>
            <h2>{content.title}</h2>
          </Link>
          <p className="date created"><em>Updated: {moment(content.updated).format("MMM D, YYYY h:MM A")}</em></p>
          <div className="summary" dangerouslySetInnerHTML={{ __html: content.extractedSummary ? content.extractedSummary : content.body }}></div>
          <Link to={"/bulletin/" + content.id} className="btn btn-primary">Read full bulletin</Link>
        </CardBody>
      </Card>
    );
  }

  renderPagination() {
    var pageItems = [];    
    for (let p = 1; p < this.state.totalPages + 1; p++) {
      pageItems.push(
        <PaginationItem active={this.state.page === p} key={p}>
          <PaginationLink onClick={() => this.paginationChangePage(p)}>{p}</PaginationLink>
        </PaginationItem>
      );
    }

    return (
      <Pagination>
        <PaginationItem disabled={this.state.page <= 1}>
          <PaginationLink previous onClick={() => this.paginationChangePageRelative(-1)} />
        </PaginationItem>
        {pageItems}
        <PaginationItem disabled={this.state.page === this.state.totalPages}>
          <PaginationLink next onClick={() => this.paginationChangePageRelative(1)} />
        </PaginationItem>
      </Pagination>
    );
  }

  render() {
    let innerContent = <p>Loading bulletins...</p>;

    if (this.state.bulletinList && this.state.bulletinList.contentResults) {

      if (this.state.bulletinList.contentResults.length < 1) {
        innerContent = <div>No bulletin data, or error occurred.</div>
      }

      innerContent = 
          this.state.bulletinList.contentResults.map((b) => 
            this.renderBulletinCard(b)
          );
    }    

    return (
      <div className="bulletin">
        <Jumbotron fluid>
          <Container>
            <h1 className="display-5">IT Bulletins</h1>
            <hr className="my-2" />
            <p className="lead">View important announcements, learn what's new through release notes, and see the exciting stuff that what we've been up to!</p>
          </Container>
        </Jumbotron>
        <Container>
          <Row>
            <Col md="12">
              {innerContent}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              {this.renderPagination()}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

}
